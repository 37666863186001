import "./FAQ.scss";

export default function FAQ() {
  const questions = [
    {
      title: "¿Qué es el adelanto de renta?",
      text: "El adelanto de renta es un servicio para propietarios con contratos de arrendamiento activos. Te permite recibir un pago anticipado de tus futuras rentas con un pequeño descuento, dándote acceso inmediato a liquidez.",
    },
    {
      title:
        "¿Qué tipo de propiedades son elegibles para el adelanto de rentas?",
      text: "Ouner se especializa exclusivamente en propiedades de vivienda. Esto incluye casas y departamentos que tengan un contrato de arrendamiento vigente.",
    },
    {
      title: "¿Cómo se determina cuánto puedo adelantar?",
      text: "El monto del adelanto se basa en el valor mensual de la renta y la duración del contrato de arrendamiento. Puedes obtener una estimación personalizada a través de nuestra plataforma, proporcionando algunos detalles clave sobre tu propiedad y el contrato actual.",
    },
    {
      title: "¿Necesito la autorización del inquilino?",
      text: "No. Ouner adquiere los derechos de los pagos futuros de la renta, pero esto no afecta el contrato de arrendamiento entre el propietario y el inquilino.",
    },
    {
      title: "¿Cuánto tiempo tarda la aprobación del adelanto de renta?",
      text: "El proceso de aprobación de un adelanto de renta puede tomar entre 24 y 72 horas, dependiendo de la rapidez con la que se proporcionen los documentos necesarios y se evalúe el contrato de arrendamiento. Una vez aprobado, los fondos se transfieren de inmediato.",
    },
  ];

  return (
  <div className="FAQ">
    <div className="column column--40">
      <h3>Preguntas frecuentes</h3>
      <p>
        Todo lo que necesitas saber sobre el producto y la facturación.
        ¿No encuentras la respuesta que buscas? Por favor,{" "}
        <a
          href="https://wa.me/message/42HEWEITMKLEK1"
          target="_blank"
          rel="noopener noreferrer"
        >
          chatea con nuestro equipo
        </a>
      </p>
    </div>
    <div className="column">
      <div className="questions">
        {questions.map((question, index) => (
          <div key={index} className="question">
            <h6 className="question__title">{question.title}</h6>
            <p className="question__text">{question.text}</p>
            <div className="divider" />
          </div>
        ))}
      </div>
    </div>
  </div>);
}
