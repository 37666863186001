import axios from "axios";
import { IAdvancementData } from "../Components/Advancement/Form/Form";

class ounerFunctions {
  private baseURL = "https://us-central1-galaxa-ouner.cloudfunctions.net";
  private env = process.env.REACT_APP_ENVIRONMENT ?? "local"

  async createHubSpotDeal(params: IAdvancementData): Promise<number> {
    const path = "/createHubSpotDeal";

    const dealData = {
      params,
      env: this.env
    };

    try {
      const response = await axios.post(
        `${this.baseURL}${path}`,
        dealData,
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      return response.status;
    } catch (error) {
      console.error("Error creating deal:", error);
      throw new Error("Failed to create deal");
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ounerFunctions();
