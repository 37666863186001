import { Routes, Route } from "react-router-dom";

import AffiliatesPage from "./Pages/Affiliates/Affiliates";
import ContractPage from "./Pages/Contract/Contract";
import CreateContractPage from "./Pages/CreateContract/CreateContract";
import TermsAndConditions from "./Pages/Terms&Conditions/TermsAndConditions";
import AdvancementPage from "./Pages/Advancement/Advancement";

function App() {
  return (
    <Routes>
      <Route path="/" element={<AdvancementPage />} />
      <Route path="/contratos" element={<ContractPage />} />
      <Route path="/contratos/crear" element={<CreateContractPage />} />
      <Route
        path="/contratos/terminos-y-condiciones"
        element={<TermsAndConditions />}
      />

      <Route path="/afiliados" element={<AffiliatesPage />} />
    </Routes>
  );
}

export default App;
