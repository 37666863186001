import dayjs from "dayjs";
import { TContract } from "../Models/Contract";

export const contract = (data: TContract) => {
  const duration = data.property.endDate?.diff(data.property.startDate, "months");
  const depositEquivalent = (data.payment.depositAmount / data.payment.amount).toFixed(1);

  const today = dayjs();
  
  return `
CONTRATO DE ARRENDAMIENTO DE VIVIENDA

CONTRATO DE ARRENDAMIENTO que celebran por una parte, ${data.identification.lessor.name}, a quien en lo sucesivo se le denominará "EL ARRENDADOR", y por la otra parte, ${data.identification.tenant.name}, a quien en lo sucesivo se le denominará "EL ARRENDATARIO", ambos mayores de edad, con capacidad legal para contratar y obligarse, y que se sujetan a las siguientes:

CLÁUSULAS:
PRIMERA. OBJETO DEL CONTRATO
El ARRENDADOR otorga en arrendamiento al ARRENDATARIO el inmueble ubicado en ${data.property.address}, el cual se destinará exclusivamente para uso habitacional.

SEGUNDA. DURACIÓN
El presente contrato tendrá una duración de ${duration} meses, a partir del día ${data.property.startDate?.format("YYYY DD MM")}, y finalizará el día ${data.property.endDate?.format("YYYY DD MM")}. Una vez concluido este plazo, el contrato podrá ser renovado de común acuerdo entre las partes.

TERCERA. RENTA
El ARRENDATARIO se obliga a pagar al ARRENDADOR la cantidad de $${data.payment.amount} (Pesos Mexicanos) mensuales, misma que deberá ser pagada de manera anticipada, dentro de los primeros ${data.payment.payday } días de cada mes, en el domicilio del ARRENDADOR o mediante depósito bancario a la cuenta ${data.payment.accountNumber}, propiedad del ARRENDADOR.

CUARTA. DEPÓSITO
El ARRENDATARIO entrega al ARRENDADOR en este acto la cantidad de $${data.payment.depositAmount}, que equivale a ${depositEquivalent} mes(es) de renta, en concepto de depósito en garantía, el cual será devuelto al ARRENDATARIO al término del contrato, siempre y cuando no existan adeudos o daños en el inmueble arrendado.

QUINTA. MANTENIMIENTO Y REPARACIONES
El ARRENDATARIO se obliga a mantener el inmueble en buen estado de conservación, y se hace responsable de las reparaciones menores necesarias que surjan por el uso normal del inmueble. Las reparaciones mayores que no resulten del mal uso del inmueble serán responsabilidad del ARRENDADOR.

SEXTA. SERVICIOS
El ARRENDATARIO será responsable del pago de los servicios de agua, luz, gas, teléfono, internet, cuotas de mantenimiento y demás servicios que se generen por el uso del inmueble, debiendo acreditar su pago mensual al ARRENDADOR si este lo solicita.

SÉPTIMA. USO DEL INMUEBLE
El ARRENDATARIO se compromete a utilizar el inmueble únicamente para fines habitacionales y no podrá subarrendarlo, ceder sus derechos u otorgar el uso del inmueble a terceras personas sin el consentimiento expreso y por escrito del ARRENDADOR.

OCTAVA. TERMINACIÓN ANTICIPADA
En caso de que cualquiera de las partes desee dar por terminado el presente contrato antes de la fecha de vencimiento, deberá dar aviso con 30 días de anticipación a la otra parte.

NOVENA. CAUSAS DE RESCISIÓN
El ARRENDADOR podrá dar por terminado el presente contrato sin responsabilidad alguna en los siguientes casos:

Falta de pago de la renta por parte del ARRENDATARIO.
Uso indebido del inmueble por parte del ARRENDATARIO.
Subarrendar o ceder el inmueble sin autorización.

DÉCIMA. JURISDICCIÓN
Para la interpretación y cumplimiento del presente contrato, las partes se someten a la jurisdicción y competencia de los tribunales de la Ciudad de México, renunciando expresamente a cualquier otro fuero que pudiera corresponderles en razón de su domicilio presente o futuro.

Leído que fue el presente contrato por las partes, lo firman de conformidad en la Ciudad de México, a los ${today.format("D")} días del mes de ${today.format("MM")} de ${today.format("YYYY")}.

EL ARRENDADOR                       

Nombre: _______________            

Firma: ________________
 
EL ARRENDATARIO

Nombre: _______________

Firma: ________________
`};
