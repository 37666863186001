import "./TwoProperty.scss";

import TextBox from "../../../ui/TextBox/TextBox";
import DatePicker from "../../../ui/DatePicker/DatePicker";

import { forwardRef, useState } from "react";
import { TTwoProperty } from "../../../Models/Contract";

interface TwoPropertyProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDataChange: (data: { [key: string]: TTwoProperty }) => void;
}

const TwoProperty = forwardRef<HTMLInputElement, TwoPropertyProps>(
  ({onDataChange, ...props}, ref) => {
    const [formValues, setFormValues] = useState<TTwoProperty>({
      address: "",
      startDate: undefined,
      endDate: undefined,
    });

      const handleChange = (
        name: string,
        value: any
      ) => {
        const updatedFormValues: TTwoProperty = { ...formValues };
        let currentLevel: any = updatedFormValues;
        currentLevel[name as keyof typeof currentLevel] = value

        setFormValues(updatedFormValues);
        onDataChange({ "property": updatedFormValues });
      };
  
    return (
      <div className="two-property">
        <div className="two-property--section">
          <p className="title">Inmueble</p>

          <div className="fields">
            <TextBox
              name="address"
              placeholder="Dirección completa"
              value={formValues.address}
              onChange={(data) => handleChange("address", data.target.value)}
            />

            <div className="divider" />
          </div>
        </div>

        <div className="two-property--section">
          <p className="title">Duración del contrato</p>

          <div className="fields">
            <div className="fields__document">
              <span className="start_date">
                <DatePicker
                  name="startDate"
                  value={formValues.startDate}
                  onChange={(data) => handleChange("startDate", data)}
                  placeholder="Fecha de inicio"
                />
              </span>
              <span className="end_date">
                <DatePicker
                  name="address"
                  value={formValues.endDate}
                  onChange={(data) => handleChange("endDate", data)}
                  placeholder="Fecha de finalización"
                />
              </span>
            </div>
            <div className="divider" />
          </div>
        </div>
      </div>
    );
  });

export default TwoProperty;
