import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDsVbjuXG7ZLHFHQvlLV4R_7MBHPCAZolQ",
  authDomain: "galaxa-ouner.firebaseapp.com",
  projectId: "galaxa-ouner",
  storageBucket: "galaxa-ouner.appspot.com",
  messagingSenderId: "586042048888",
  appId: "1:586042048888:web:c0eb8783e1bd45c594c88d",
  measurementId: "G-95D1GD9V6Y"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
