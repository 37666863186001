import "./Affiliates.scss";

import BarChartIcon from '@mui/icons-material/BarChart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MovingIcon from '@mui/icons-material/Moving';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import AppBar from "../../ui/AppBar/AppBar";
import Footer from "../../ui/Footer/Footer";
import Button from "../../ui/Button/Button";
import FAQ from "../../Components/FAQ/FAQ";
import { useNavigate } from "react-router-dom";


function AffiliatesPage() {
  const navigate = useNavigate();

  const benefits = [
    {
      icon: <MonetizationOnIcon />,
      title: "Ganancias Rápidas y Fáciles",
      description: "Gana un porcentaje de cada adelanto de alquiler proporcionado a tus clientes referidos.",
    },
    {
      icon: <PersonAddIcon />,
      title: "Proceso de Registro Simple",
      description: "Únete a través de WhatsApp, recibe tu enlace de referido personalizado y comienza a compartir.",
    },
    {
      icon: <MovingIcon />,
      title: "Flujo de Ingresos Confiable",
      description: "Ayuda a los clientes a asegurar un adelanto de sus ingresos de alquiler, agregando valor a tus servicios.",
    },
    {
      icon: <BarChartIcon />,
      title: "Seguimiento en Tiempo Real",
      description: "Cada enlace de referido es rastreado, para que puedas monitorear fácilmente tus ganancias.",
    }
  ]

  const steps = [
    {
      title: "Únete al programa",
      description: "Toca el botón 'Unirse al Programa' para iniciar la conversación de registro en WhatsApp.",
    },
    {
      title: "Recibe tu enlace único",
      description: "Obtén tu enlace de referido personalizado para compartir con propietarios.",
    },
    {
      title: "Comparte con propietarios",
      description: "Comparte el enlace con propietarios de apartamentos que puedan estar interesados en un adelanto de sus ingresos de alquiler.",
    },
    {
      title: "Gana comisiones",
      description: "Cuando un propietario se registra usando tu enlace, ganarás una comisión por el adelanto proporcionado.",
    },
  ]

  const CallToActionBox = ({ className, variant }: { className: string, variant: boolean }) => {
    return (
      <div className={`affiliates-page__call-to-action-box ${className}`}>
        <h3>{ variant ? "¿Listo para empezar a ganar?" : "¡Comienza tu viaje de ganancias hoy!"}</h3>
        <p>{ variant ? "¡Únete a nuestro programa ahora y comienza a referir propietarios hoy mismo!" :   "Únete a nuestro programa de referidos y desbloquea una nueva fuente de ingresos para tu negocio inmobiliario."}</p>
        <Button
        className="whatsapp"
          text="Únete a el programa ahora"
          variant="outlined"
          icon={<WhatsAppIcon />}
          onClick={() => 
            window.open("https://wa.link/2bvu2t", "_blank", "noopener,noreferrer")
          }
        />
      </div>
    )
  } 

  return (
    <div className="affiliates-page">
      <AppBar>
        <Button
          text="Solicita tu adelanto ahora"
          variant="filled"
          onClick={() => {navigate("/")}}
        />
      </AppBar>

      <div className="affiliates-page__hero">
        <div className="affiliates-page__hero--content">
          <h1>¡Gana ayudando a propietarios a obtener un adelanto de alquiler!</h1>
          <h2>Únete a nuestro programa exclusivo y gana comisiones por cada referido exitoso. Ayuda a los propietarios a acceder a sus ingresos de alquiler por adelantado mientras haces crecer tu negocio.</h2>

          <Button
            className="whatsapp"
            text="Unirse al programa"
            variant="text"
            icon={<WhatsAppIcon />}
            onClick={() =>
              window.open("https://wa.link/2bvu2t", "_blank", "noopener,noreferrer")
            }
          />
        </div>
        <img
          className="affiliates-page__hero--image"
          src="https://firebasestorage.googleapis.com/v0/b/galaxa-ouner.firebasestorage.app/o/ouner_affiliates_hero.webp?alt=media&token=18566143-6490-4950-96c3-5e5c4fb27682"
          alt="Ouner Affiliates"
        />
      </div>

      <div className="affiliates-page__benefits">
        <h2>¿Por qué unirse a nuestro programa de referidos?</h2>

        <div className="affiliates-page__benefits__list">
          {benefits.map((benefit, index) => (
            <div className="affiliates-page__benefits__list--item" key={index}>
              <div className="icon">
                {benefit.icon}
              </div>
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="affiliates-page__how-works">
        <h2>¿Cómo funciona nuestro programa de referidos?</h2>
        <div className="affiliates-page__how-works__steps">
          {steps.map((step, index) => (
            <div className="affiliates-page__how-works__steps--item" key={index}>
              <span className="number">{index + 1}</span>
              <div className="content">
                <h4>{step.title}</h4>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <CallToActionBox
        className="whatsapp-background"
        variant={true}
      />

      <FAQ />

      <CallToActionBox
        className="primary-background"
        variant={false}
      />

      <Footer />
    </div>
  );
}

export default AffiliatesPage;
