import jsPDF from "jspdf";

export function generatePDF(content: string) {
  const doc = new jsPDF();
  const marginLeft = 24;
  const marginTop = 24;
  const maxWidth = 150;
  const lineHeight = 10;
  const pageHeight = doc.internal.pageSize.height;
  let y = marginTop;

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);

  const lines = doc.splitTextToSize(content, maxWidth);

  lines.forEach((line: string) => {
    if (y + lineHeight > pageHeight - marginTop) {
      doc.addPage();
      y = marginTop;
    }
    doc.text(line, marginLeft, y);
    y += lineHeight;
  });

  const pdfBlob = doc.output('blob');
  const url = URL.createObjectURL(pdfBlob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'contrato_arrendamiento.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
