import "./ThreePayment.scss";

import TextBox from "../../../ui/TextBox/TextBox";
import { forwardRef, useState } from "react";
import { TThreePayment } from "../../../Models/Contract";
import Checkbox from "../../../ui/CheckBox/CheckBox";

interface ThreePaymentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDataChange: (data: { [key: string]: TThreePayment }) => void;
}

const ThreePayment = forwardRef<HTMLInputElement, ThreePaymentProps>(
  ({onDataChange, ...props}, ref) => {

    const [formValues, setFormValues] = useState<TThreePayment>({
      amount: 0,
      accountNumber: 0,
      payday: 0,
      depositAmount: 0,
      allowTermsAndConditions: "false",
    });

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      let updatedFormValues: TThreePayment = { ...formValues };
      let currentLevel: any = updatedFormValues;
      currentLevel[name as keyof typeof currentLevel] = value;

      setFormValues(updatedFormValues);
      onDataChange({ "payment": updatedFormValues });
    };

    return (
      <div className="three-payment">
        <div className="three-payment--section">
          <p className="title">Monto de renta</p>

          <div className="fields">
            <TextBox
              name="amount"
              placeholder="Monto de renta en pesos"
              value={formValues.amount}
              onChange={handleChange}
            />
            <div className="fields__account">
              <span className="account-number">
                <TextBox
                  name="accountNumber"
                  placeholder="Cuenta bancaria para el deposito"
                  value={formValues.accountNumber}
                  onChange={handleChange}
                />
              </span>
              <span className="payday">
                <TextBox
                  name="payday"
                  placeholder="Día de pago"
                  value={formValues.payday}
                  onChange={handleChange}
                />
              </span>
            </div>

            <div className="divider" />
          </div>
        </div>

        <div className="three-payment--section">
          <p className="title">Deposito</p>

          <div className="fields">
            <TextBox
              name="depositAmount"
              placeholder="Monto del deposito"
              value={formValues.depositAmount}
              onChange={handleChange}
            />
            <div className="divider" />
          </div>
        </div>

        <div className="three-payment--section">
          <p className="title">Términos y condiciones</p>

          <div className="fields">
            <Checkbox
              label="Acepto los términos y condiciones"
              name="allowTermsAndConditions"
              path="/contratos/terminos-y-condiciones"
              checked={
                formValues.allowTermsAndConditions.toLowerCase() === "true"
              }
              onChange={(name, data) => {
                const simulatedEvent = {
                  target: {
                    name: name,
                    value: data ? "true" : "false",
                  },
                } as React.ChangeEvent<HTMLInputElement>;

                handleChange(simulatedEvent);
              }}
            />
            <div className="divider" />
          </div>
        </div>
      </div>
    );
  })

export default ThreePayment;
