import React from "react";
import "./IconButton.scss";

type ButtonProps = {
  children: React.ReactNode;
  variant:
    | "filled"
    | "outlined"
    | "text"
    | "elevated"
    | "tonal"
    | "elevated-icon";
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({ children, variant, onClick }) => {
  return (
    <button className={`icon-button button--${variant}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
