import "./TextBox.scss";
import React, { forwardRef, useState, useEffect } from "react";

interface TextBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  errorText?: string;
  customValidation?: (value: any) => string | void
}

const TextBox = forwardRef<HTMLInputElement, TextBoxProps>(
  ({ errorText, type, customValidation, required, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState<string | undefined>(
      props.value as string
    );
    const [error, setError] = useState<string | null>(null);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => {
      setIsFocused(false);
      validateInput();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = e.target.value;

      if (type === "number") {
        newValue = newValue.replace(",", "");
      }

      const isNumeric = type === "number" && /^[0-9]*$/.test(newValue);
    
      if (isNumeric || type !== "number") {
        setInputValue(newValue);
        props.onChange?.(e);
        validateInput(newValue);
      }
    };

    const validateInput = (value = inputValue) => {
      let validationError = null;

      if (required && !value) {
        validationError = "Este campo es obligatorio";
      }

      if (type === "email" && value) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(value)) {
          validationError = "El formato del correo no es válido";
        }
      }

      if (customValidation) {
        const error = customValidation(value);
        validationError = error ?? null;
      }

      setError(validationError);
    };

    useEffect(() => {
      if (props.value !== undefined) {
        setInputValue(props.value as string);
      }
    }, [props.value]);

    const showLabelRaised =
      isFocused || (inputValue && inputValue.toString().length > 0);

    return (
      <div className={`text-box ${error ? "text-box--error" : ""}`}>
        <input
          {...props}
          ref={ref}
          className="text-box__input"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={inputValue || ""}
        />
        <label className={`text-box__label ${showLabelRaised ? "raised" : ""}`}>
          {props.placeholder}
        </label>
        <div className="text-box__underline"></div>
        {error && <div className="text-box__error">{errorText || error}</div>}
      </div>
    );
  }
);

export default TextBox;
