import './TermsAndConditions.scss'
import AppBar from "../../ui/AppBar/AppBar";

function TermsAndConditions() {
  return (
    <>
      <AppBar />
      <div className="terms-and-conditions-page">
        <h4>
          Términos y condiciones
        </h4>
        <p>
          Ouner, en adelante ("la Empresa") proporciona a los usuarios de este
          sitio web la posibilidad de descargar contratos de arrendamiento con
          base en los datos proporcionados por ellos mismos. Sin embargo, la
          Empresa no es responsable del contenido, precisión, calidad o
          adecuación de los documentos generados. Estos documentos son
          proporcionados únicamente con fines informativos y no constituyen
          asesoría legal, ni deben ser considerados como tal. La Empresa no es
          un despacho de abogados ni ofrece servicios de asesoría legal. La
          creación de los documentos no implica ninguna relación
          abogado-cliente. El uso de estos documentos es bajo la total
          responsabilidad del usuario, quien deberá verificar y ajustar el
          contenido conforme a sus necesidades y circunstancias particulares. La
          Empresa no asume responsabilidad alguna por errores, omisiones,
          interpretaciones o cualquier otra cuestión derivada del uso de los
          documentos, ni por los daños, perjuicios o pérdidas que puedan
          resultar de la utilización de los mismos. Se recomienda que los
          usuarios consulten a un abogado calificado antes de utilizar cualquier
          documento descargado de este sitio web para asegurarse de su validez y
          aplicabilidad en su caso específico.
        </p>
    </div>
    </>
  );
}

export default TermsAndConditions;
