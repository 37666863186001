import "./Checkbox.scss";

import { useNavigate } from "react-router-dom";
import React, { useState, ChangeEvent } from "react";

interface CheckboxProps {
  label: string;
  path?: string;
  name: string;
  checked?: boolean;
  onChange: (name: string, checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  path,
  checked = false,
  onChange,
}) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    onChange(name, event.target.checked);
  };

  const handleLabelClick = () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        id="custom-checkbox"
        checked={isChecked}
        onChange={handleOnChange}
      />
      <label
        htmlFor="custom-checkbox"
        onClick={handleLabelClick}
        style={{ cursor: path ? "pointer" : "default" }}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
