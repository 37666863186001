import "./Dropdown.scss";
import React, { useState } from "react";

interface DropdownProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  name: string;
  options: string[];
  placeholder?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  options,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleDropdownToggle = () => setIsOpen(!isOpen);
  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);

    if (onChange) {
      const event = {
        target: { value: option, name } as HTMLSelectElement,
      } as React.ChangeEvent<HTMLSelectElement>;
      onChange(event);
    }
  };

  return (
    <div className="dropdown">
      <div className="dropdown__label" onClick={handleDropdownToggle}>
        {selectedOption ? selectedOption : placeholder}
      </div>
      {isOpen && (
        <ul className="dropdown__list">
          {options.map((option) => (
            <li
              key={option}
              className="dropdown__item"
              onClick={() => handleOptionSelect(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
