import "./DatePicker.scss";
import React, { useState, useRef, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import TextBox from "../TextBox/TextBox";
import dayjs from "dayjs";

interface DatePickerProps {
  value?: dayjs.Dayjs;
  onChange: (newValue: dayjs.Dayjs) => void;
  maxDate?: dayjs.Dayjs;
  minDate?: dayjs.Dayjs;
  placeholder: string;
  errorMessage?: string;
  name: string;
  format?: string; 
}

const CustomDatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  maxDate,
  minDate,
  placeholder,
  errorMessage,
  name,
  format
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleValue = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      onChange(newValue);
      setIsCalendarOpen(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="date-picker" ref={containerRef}>
        <TextBox
          placeholder={placeholder}
          onClick={() => setIsCalendarOpen(true)}
          errorText={errorMessage}
          value={value?.format(format ?? "YYYY-MM-DD")}
        />

        {isCalendarOpen && (
          <>
            <div className="overlay"></div>
            <div className="calendar">
              <DateCalendar
                value={value}
                onChange={(date) => handleValue(date)}
                maxDate={maxDate}
                minDate={minDate}
              />
            </div>
          </>
        )}
      </div>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
