import './OneStep.scss';

import { forwardRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import TextBox from "../../../../../ui/TextBox/TextBox";
import Button from "../../../../../ui/Button/Button";

interface IAdvancement {
  rent: {
    value: number,
    formatted: string
  };
  months: number;
}

interface StepOneProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDataChange: (data: any) => void;
  nextStep: () => void;
}

const StepOne = forwardRef<HTMLInputElement, StepOneProps>(
  ({onDataChange, nextStep, ...props}, ref) => {
    const [advancement, setAdvancement] = useState<IAdvancement>({
      rent: {
        value: 0,
        formatted: "",
      },
      months: 0,
    });
    const [showResults, setShowResults] = useState(false);

    const convertStringToNumber = (value: string): number => {
      const cleanedValue = value.replace(/,/g, "");
      return Number(cleanedValue);
    };
      
    const formatNumber = (value: number): string => {
      return new Intl.NumberFormat("en-US").format(value);
    };

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      callback?: () => void
    ) => {
      const { name, value } = e.target;
      const advancementUpdate: IAdvancement = { ...advancement };
      const keys = name.split(".");
      let currentLevel: any = advancementUpdate;
      keys.slice(0, -1).forEach((key) => {
        currentLevel = currentLevel[key as keyof typeof currentLevel];
      });
      currentLevel[keys[keys.length - 1] as keyof typeof currentLevel] =
        convertStringToNumber(value);

      setAdvancement(advancementUpdate);
      onDataChange({ "details": advancementUpdate });

      if(callback) {
        callback();
      }
    };

    const handleSubmit = () => {
      if (advancement.rent.value > 0 && advancement.months > 0) {
        setShowResults(true);
      }
    };

    const calculateTotal = () => {
      const total = advancement.rent.value * advancement.months;    
      const baseCommissionRate = 0.165;   
      const extraCommissionRate =
        advancement.months > 6 ? (advancement.months - 6) * 0.012 : 0;    

      const commissionRate = baseCommissionRate + extraCommissionRate;
      const commission = parseFloat((total * commissionRate).toFixed(2));    

      return formatNumber(total - commission);
    };

    return (
      <div className="step-one">
        <p className="step-one--title">Solicita tu adelanto de renta</p>
        <div className="step-one--fields">
          <TextBox
            name="rent.value"
            placeholder="¿Cuánto cobras de renta al mes?"
            value={advancement.rent.formatted}
            type="number"
            onChange={(e) => {
              const formatValue = () => {
                const advancementUpdate: IAdvancement = { ...advancement };
                const value = advancement.rent.value;

                advancementUpdate.rent.formatted =
                  value === 0 ? "" : formatNumber(advancement.rent.value);
              };

              handleChange(e, formatValue);
            }}
          />
          <TextBox
            name="months"
            placeholder="¿Cuántos meses quieres adelantar?"
            value={advancement.months}
            type="number"
            onChange={handleChange}
            customValidation={(value) => {
              if (value > 35) {
                return "Los meses no pueden ser más de 35";
              }
            }}
          />
        </div>
        {(!showResults ||
          advancement.rent.value === 0 ||
          advancement.months === 0) && (
          <div className="step-one--actions">
            <Button
              variant="filled"
              text="Solicitar adelanto de renta"
              icon={<ArrowForwardIosIcon />}
              onClick={handleSubmit}
              disabled={
                advancement.rent.value <= 0 ||
                advancement.months < 1 ||
                advancement.months > 35
              }
            />
            <Button
              className="whatsapp"
              text="Tengo más preguntas"
              variant="text"
              icon={<WhatsAppIcon />}
              onClick={() =>
                window.open("https://wa.me/message/42HEWEITMKLEK1", "_blank")
              }
            />
          </div>
        )}
        {advancement.rent.value > 0 &&
          advancement.months > 0 &&
          showResults && (
            <div className="step-one__results">
              <div className="divider" />
              <p className="step-one__results--result">
                Te adelantamos hasta <strong>{calculateTotal()}</strong> MXN por{" "}
                <strong>{advancement.months}</strong> meses de renta. Sin
                esperas, ni complicaciones.
              </p>
              <p className="step-one__results--disclaimer">
                *Cálculo estimado y queda sujeto a aprobación y otros factores
              </p>
              <div className="step-one__results--action">
                <Button
                  variant="filled"
                  text="Solicitar adelanto de renta"
                  icon={<ArrowForwardIosIcon />}
                  onClick={nextStep}
                  disabled={
                    advancement.rent.value <= 0 ||
                    advancement.months < 1 ||
                    advancement.months > 35
                  }
                />
                <Button
                  className="whatsapp"
                  text="Tengo más preguntas"
                  variant="text"
                  icon={<WhatsAppIcon />}
                  onClick={() =>
                    window.open(
                      "https://wa.me/message/42HEWEITMKLEK1",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
          )}
      </div>
    );
})

export default StepOne;
