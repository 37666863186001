import "./AppBar.scss";

import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../Assets/brand.png";
import IconButton from "../../ui/IconButton/IconButton";

interface AppBarProps {
  children?: React.ReactNode;
}

const AppBar: React.FC<AppBarProps> = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const DefaultOptions = () => (
    <ul>
      <li>
        <a href="https://ouner.mx" target="_blank" rel="noreferrer">
          Inicio
        </a>
      </li>
      <li>
        <a href="https://ouner.mx" target="_blank" rel="noreferrer">
          ¿Quiénes somos?
        </a>
      </li>
      <li>
        <a href="https://ouner.mx" target="_blank" rel="noreferrer">
          ¿Qué hacemos?
        </a>
      </li>
      <li>
        <a href="https://ouner.mx" target="_blank" rel="noreferrer">
          ¿Cómo funciona?
        </a>
      </li>
    </ul>
  );

  return (
    <>
      <header className="app-bar">
        <div className="app-bar__actions">
          <a href="https://ouner.mx" className="brand">
            <img src={logo} alt="Ouner Logo" />
          </a>

          <div className="options">
            {children ? children : <DefaultOptions />}
          </div>

          <span className="menu--button" onClick={toggleMenu}>
            <IconButton variant="elevated-icon">
              {menuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </span>
        </div>

        {menuOpen && (
          <div className="app-bar__menu">
            {children ? children : <DefaultOptions />}
          </div>
        )}
      </header>
    </>
  );
};

export default AppBar;
