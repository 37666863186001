import "./Advancement.scss";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AddTaskIcon from '@mui/icons-material/AddTask';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import EmergencySharpIcon from '@mui/icons-material/EmergencySharp';

import AppBar from "../../ui/AppBar/AppBar";
import Button from "../../ui/Button/Button";
import Footer from "../../ui/Footer/Footer";

import ImgMainOne from "../../Assets/Advancement/MainOne.jpg"
import ImgMainTwo from "../../Assets/Advancement/MainTwo.jpg";
import ImgMainThree from "../../Assets/Advancement/MainThree.jpg";
import ImgFormBackground from "../../Assets/Advancement/FormBackground.jpg";

import AdvancementForm from "../../Components/Advancement/Form/Form";
import FAQ from "../../Components/FAQ/FAQ";

function AdvancementPage() {
  const navigate = useNavigate();

  const formSectionRef = useRef<HTMLDivElement | null>(null);
  
  const handleScrollToForm = () => {
    if (formSectionRef.current) {
      formSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <AppBar>
        <Button
          text="Solicita tu adelanto ahora"
          variant="filled"
          onClick={handleScrollToForm}
        />
      </AppBar>

      <div className="advancement">
        <div className="advancement__hero">
          <div className="column">
            <div className="column--texts">
              <h2>Liquidez inmediata, hasta 3 años de renta por adelantado</h2>
              <p>
                Con Ouner, recibe hasta 3 años de renta por adelantado en menos
                de 48 horas, sin deudas ni riesgos. Simplifica tu vida, nosotros
                nos encargamos del resto
              </p>
            </div>
            <div className="column--actions">
              <Button
                text="Solicita tu adelanto ahora"
                variant="filled"
                onClick={handleScrollToForm}
              />
              <Button
                className="whatsapp"
                text="Conocer más de Ouner"
                variant="text"
                icon={<WhatsAppIcon />}
                onClick={() =>
                  window.open("https://wa.me/message/42HEWEITMKLEK1", "_blank")
                }
              />
            </div>
          </div>
          <div className="column">
            <div className="column--image">
              <div className="image-column-one">
                <img src={ImgMainOne} alt="Ouner Logo" />
                <img src={ImgMainTwo} alt="Ouner Logo" />
              </div>
              <div className="image-column-two">
                <img src={ImgMainThree} alt="Ouner Logo" />
              </div>
            </div>
          </div>
        </div>

        <div className="advancement__benefits">
          <h3>Ventajas del adelanto de renta</h3>

          <div className="advancement__benefits__items">
            <div className="advancement__benefits__items__card">
              <div className="advancement__benefits__items__icon">
                <PhoneAndroidIcon />
              </div>
              <p className="advancement__benefits__items__content">
                100% online, fácil y rápido
              </p>
            </div>
            <div className="advancement__benefits__items__card advancement__benefits__items__card--dark">
              {" "}
              {/* Cambié el orden de las clases */}
              <div className="advancement__benefits__items__icon">
                <AttachMoneyIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Te adelantamos hasta 3 años de renta
              </p>
            </div>
            <div className="advancement__benefits__items__card">
              <div className="advancement__benefits__items__icon">
                <EmergencySharpIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Asumimos los riesgos por ti
              </p>
            </div>
            <div className="advancement__benefits__items__card advancement__benefits__items__card--dark">
              <div className="advancement__benefits__items__icon">
                <HandshakeIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Gestionamos la renta por completo
              </p>
            </div>
            <div className="advancement__benefits__items__card">
              <div className="advancement__benefits__items__icon">
                <AddTaskIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Sin deudas ni complicaciones
              </p>
            </div>
            <div className="advancement__benefits__items__card advancement__benefits__items__card--dark">
              <div className="advancement__benefits__items__icon">
                <QueryBuilderIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Aprobación en solo 48 horas
              </p>
            </div>
          </div>
        </div>

        <div className="advancement__form" ref={formSectionRef}>
          <img src={ImgFormBackground} alt="Ouner Logo" />
          <AdvancementForm />
        </div>

        <FAQ />
      </div>

      <Footer />
    </>
  );
}

export default AdvancementPage;
