import './TwoStep.scss'

import { forwardRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import TextBox from "../../../../../ui/TextBox/TextBox";
import Button from "../../../../../ui/Button/Button";

interface StepTwoProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDataChange: (data: any) => void;
  nextStep: () => void;
}

interface IUser {
  name: string;
  email: string;
  phone: string;
}

const StepTwo = forwardRef<HTMLInputElement, StepTwoProps>(
  ({onDataChange, nextStep, ...props}, ref) => {
  
  const [user, setUser] = useState<IUser>({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    callback?: () => void
  ) => {
    const { name, value } = e.target;
    const advancementUpdate: IUser = { ...user };
    const keys = name.split(".");
    let currentLevel: any = advancementUpdate;
    keys.slice(0, -1).forEach((key) => {
      currentLevel = currentLevel[key as keyof typeof currentLevel];
    });
    
    currentLevel[keys[keys.length - 1] as keyof typeof currentLevel] = value;

    setUser(advancementUpdate);
    onDataChange({ user: advancementUpdate });

    if (callback) {
      callback();
    }
  };

  let formCompleted =
    user.name.length > 0 &&
    user.email.length > 0 &&
    user.phone.length > 0;

  return (
    <div className="step-two">
      <p className="step-two--title">Datos del usuario</p>

      <div className="step-two__fields">
        <div className="step-two__fields--group">
          <TextBox
            name="name"
            placeholder="Nombre completo"
            value={user.name}
            onChange={handleChange}
          />
        </div>

        <TextBox
          name="email"
          placeholder="Correo"
          type="email"
          errorText="Correo invalido"
          value={user.email}
          onChange={handleChange}
        />
        <TextBox
          name="phone"
          placeholder="Número celular"
          type="number"
          value={user.phone}
          onChange={handleChange}
        />
      </div>

      <div className="step-one__results--action">
        <Button
          variant="filled"
          text="Enviar solicitud"
          icon={<ArrowForwardIosIcon />}
          onClick={nextStep}
          disabled={!formCompleted}
        />
      </div>
    </div>
  );
})


export default StepTwo;
