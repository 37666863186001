import './ThreeStep.scss';

import { forwardRef } from "react";

interface StepThreeProps extends React.InputHTMLAttributes<HTMLInputElement> {
}

const StepThree = forwardRef<HTMLInputElement, StepThreeProps>(
  ({...props}, ref) => {

    return (
      <div className="step-three">
        <p className="thank-you-message">
          Solicitud enviada. Nuestro equipo se pondrá en contacto contigo muy
          pronto.
        </p>
      </div>
    );
  })

export default StepThree;
