import React, { forwardRef, useState } from "react";
import "./OneIdentification.scss";
import Dropdown from "../../../ui/Dropdown/Dropdown";
import TextBox from "../../../ui/TextBox/TextBox";
import { TOneIdentification } from "../../../Models/Contract";

interface OneIdentificationProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onDataChange: (data: {[key: string]: TOneIdentification}) => void;
}

const OneIdentification = forwardRef<HTMLInputElement, OneIdentificationProps>(
  ({onDataChange, ...props}, ref) => {
    const documentOptions = ["INE", "Pasaporte"];
    const [formValues, setFormValues] = useState<TOneIdentification>({
      lessor: {
        name: "",
        document: {
          documentType: "",
          number: "",
        },
        email: "",
      },
      tenant: {
        name: "",
        document: {
          documentType: "",
          number: "",
        },
      },
    });
  
    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      const updatedFormValues: TOneIdentification = { ...formValues };
      const keys = name.split(".");
      let currentLevel: any = updatedFormValues;
  
      keys.slice(0, -1).forEach((key) => {
        currentLevel = currentLevel[key as keyof typeof currentLevel];
      });
  
      currentLevel[keys[keys.length - 1] as keyof typeof currentLevel] = value;
  
      setFormValues(updatedFormValues);
      onDataChange({ "identification": updatedFormValues });
    };
  
    return (
      <div className="one-identification">
        <div className="one-identification--section">
          <p className="title">Información del arrendador</p>
          <div className="fields">
            <TextBox
              name="lessor.name"
              placeholder="Nombre completo"
              value={formValues.lessor.name}
              onChange={handleChange}
            />
            <div className="fields__document">
              <Dropdown
                name="lessor.document.documentType"
                placeholder="Documento"
                options={documentOptions}
                value={formValues.lessor.document.documentType}
                onChange={handleChange}
              />
              <TextBox
                name="lessor.document.number"
                placeholder="Número de documento"
                value={formValues.lessor.document.number}
                onChange={handleChange}
              />
            </div>
            <TextBox
              name="lessor.email"
              placeholder="Correo"
              type="email"
              errorText="Correo invalido"
              value={formValues.lessor.email}
              onChange={handleChange}
            />
          </div>
  
          <div className="divider" />
        </div>
  
        <div className="one-identification--section">
          <p className="title">Información del arrendatario</p>
          <div className="fields">
            <TextBox
              name="tenant.name"
              placeholder="Nombre completo"
              value={formValues.tenant.name}
              onChange={handleChange}
            />
            <div className="fields__document">
              <Dropdown
                name="tenant.document.documentType"
                placeholder="Documento"
                options={documentOptions}
                value={formValues.tenant.document.documentType}
                onChange={handleChange}
              />
              <TextBox
                name="tenant.document.number"
                placeholder="Número de documento"
                value={formValues.tenant.document.number}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  });



export default OneIdentification;
